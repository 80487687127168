<template>
<v-layout row wrap>
  <v-flex xs12 ref="container" class="text-center" v-if="videoId">
    <v-progress-circular v-if="!ready" color="primary" width="3" indeterminate/>

    <vimeo-player
        class="vimeo-player"
        ref="player"
        v-if="width && height"
        v-show="ready"
        :video-id="videoId"
        :player-width="width"
        :player-height="height"
        :options="playerOptions"
        @ready="onReady"
        @error="onError"
        @play="onPlay"
        @timeupdate="onTimeUpdate"/>

  </v-flex>
  <v-flex xs12 text-center class="accordion" :class="{'is-closed': !isOpen}">
    <v-layout row wrap>
      <v-flex shrink>
        <favorite-button-video style="opacity:0;visibility: hidden;" v-if="identifier" :identifier="identifier"/>
      </v-flex>
      <v-flex>
        <v-btn flat color="primary" @click="toggleAccordion" class="font-weight-bold text-capitalize" v-if="transcript" :small="$vuetify.breakpoint.xsOnly">
          {{ isOpen ? $t('common.actions.close_transcript') : $t('common.actions.open_transcript') }}
        </v-btn>
      </v-flex>
      <v-flex shrink text-right>
        <favorite-button-video v-if="identifier" :identifier="identifier"/>
      </v-flex>
    </v-layout>
  </v-flex>
  <v-flex xs12 mt-3 class="accordion" v-if="isOpen">
    <div class="accordion-content">
      <p class="starling-body" v-html="transcript" style="white-space: pre-wrap;"></p>
    </div>
  </v-flex>
</v-layout>
</template>

<script>
import FavoriteButtonVideo from '@/views/components/utilities/favorite-button-video.vue';

export default {
  name: 'vimeoVideo',
  components: { FavoriteButtonVideo },
  props: {
    identifier: {
      type: String,
      required: false,
    },
    videoId: {
      type: String,
      required: false,
    },
    transcript: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      ready: false,
      width: null,
      height: null,
      isOpen: false,
      transcriptOpened: false,
      startWatched: false,
      endWatched: false,
    };
  },
  computed: {
    primaryColor() {
      return getComputedStyle(document.documentElement).getPropertyValue('--StarlingPrimary1').replaceAll('#', '');
    },
    accentColor() {
      // adds 0.6 opacity
      return getComputedStyle(document.documentElement).getPropertyValue('--StarlingInfo').replaceAll('#', '') + '99';
    },
    playerOptions() {
      return {
        responsive: true,
        colors: [ this.accentColor, this.primaryColor, 'FFFFFF', '000000' ],
      };
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
    this.onResize();

    if (!this.videoId) {
      this.$emit('videoStarted');
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      const contentContainer = this.$refs.container;
      this.width = contentContainer.offsetWidth;
      this.height = this.width / 1.775;
      if (this.$refs.player) {
        this.$refs.player.$el.querySelector('iframe').width = this.width;
        this.$refs.player.$el.querySelector('iframe').height = this.height;
      }
    },
    toggleAccordion() {
      this.isOpen = !this.isOpen;
      if (this.isOpen && !this.transcriptOpened) {
        this.transcriptOpened = true;
        this.$emit('videoEvent', 'TRANSCRIPT');
      }
    },
    onReady() {
      this.ready = true;
    },
    onError() {
      this.$store.commit('setNotification', { text: this.$t('common.errors.video') });
    },
    onPlay(evt) {
      this.$emit('play', evt);
    },
    onTimeUpdate(data) {
      const PERCENTAGE_START = 0.1;
      const PERCENTAGE_END = 0.75;

      if (!this.endWatched && data.percent >= PERCENTAGE_END) {
        this.endWatched = true;
        this.$emit('videoEvent', 'COMPLETED');
      } else if (!this.startWatched && data.percent >= PERCENTAGE_START) {
        this.startWatched = true;
        this.$emit('videoEvent', 'STARTED');
      }
    },
  },
};
</script>

<style>
.vimeo-player iframe {
  display: block;
  width: 100% !important;
}
</style>
