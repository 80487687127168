var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { row: "", wrap: "" } },
    [
      _vm.videoId
        ? _c(
            "v-flex",
            {
              ref: "container",
              staticClass: "text-center",
              attrs: { xs12: "" }
            },
            [
              !_vm.ready
                ? _c("v-progress-circular", {
                    attrs: { color: "primary", width: "3", indeterminate: "" }
                  })
                : _vm._e(),
              _vm.width && _vm.height
                ? _c("vimeo-player", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.ready,
                        expression: "ready"
                      }
                    ],
                    ref: "player",
                    staticClass: "vimeo-player",
                    attrs: {
                      "video-id": _vm.videoId,
                      "player-width": _vm.width,
                      "player-height": _vm.height,
                      options: _vm.playerOptions
                    },
                    on: {
                      ready: _vm.onReady,
                      error: _vm.onError,
                      timeupdate: _vm.onTimeUpdate
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-flex",
        {
          staticClass: "accordion",
          class: { "is-closed": !_vm.isOpen },
          attrs: { xs12: "", "text-center": "" }
        },
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { shrink: "" } },
                [
                  _vm.identifier
                    ? _c("favorite-button-video", {
                        staticStyle: { opacity: "0", visibility: "hidden" },
                        attrs: { identifier: _vm.identifier }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-flex",
                [
                  _vm.transcript
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "font-weight-bold text-capitalize",
                          attrs: {
                            flat: "",
                            color: "primary",
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.toggleAccordion }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.isOpen
                                  ? _vm.$t("common.actions.close_transcript")
                                  : _vm.$t("common.actions.open_transcript")
                              ) +
                              "\n        "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { shrink: "", "text-right": "" } },
                [
                  _vm.identifier
                    ? _c("favorite-button-video", {
                        attrs: { identifier: _vm.identifier }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.isOpen
        ? _c(
            "v-flex",
            { staticClass: "accordion", attrs: { xs12: "", "mt-3": "" } },
            [
              _c("div", { staticClass: "accordion-content" }, [
                _c("p", {
                  staticClass: "starling-body",
                  staticStyle: { "white-space": "pre-wrap" },
                  domProps: { innerHTML: _vm._s(_vm.transcript) }
                })
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }