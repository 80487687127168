









import { mapState } from 'vuex';
import { FavoriteState, IFavorite, IFavoriteVideo } from '@/scripts/store/modules/favorites/types';
import Vue from 'vue';

export default Vue.extend({
  name: 'favorite-button-video',
  props: {
    identifier: { type: String },
  },
  data() {
    return {
      loading: false as boolean,
    };
  },
  computed: {
    ...mapState('favorites', {
      favorites: (state): IFavorite[] => (state as FavoriteState).favorites,
    }),
    favorite(): IFavorite | undefined {
      return this.favorites.find(f => f.type === 'VIDEO' && (f as IFavoriteVideo).video.identifier === this.identifier);
    },
    isFavorite(): boolean {
      return !!this.favorite;
    },
  },
  methods: {
    toggle(): void {
      if (this.loading) return;

      this.loading = true;

      if (!this.favorite) {
        this.$store.dispatch('favorites/addFavoriteVideo', this.identifier).finally(() => {
          this.loading = false;
        });
      } else {
        this.$store.dispatch('favorites/removeFavorite', this.favorite.id).finally(() => {
          this.loading = false;
        });
      }
    },
  },
});
